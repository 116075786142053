import "./Footer.scss";
import logo from "../../images/logo.png";
import { Link } from "react-router-dom";

import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
    return (
        <footer className="App__footer Footer container">
            <div className="Footer__left">
                <Link to="/" className="Footer__logo" title="Huwe logo">
                    <img className="Footer__logo--image" alt="" src={logo} />
                </Link>
                <p className="Footer__txt">
                    Aplikacja z zaawansowanym panelem zarządzania rekrutacjami,
                    pozwalająca kandydatom aplikować bez tworzenia CV, za pomocą
                    interaktywnego procesu rekrutacji
                </p>
                {/* <div className="Footer__social">
                    <Link to="/" className="Footer__socialLink">
                        <FaFacebookF />
                    </Link>
                    <Link to="/" className="Footer__socialLink">
                        <FaInstagram />
                    </Link>
                    <Link to="/" className="Footer__socialLink">
                        <FaTwitter />
                    </Link>
                    <Link to="/" className="Footer__socialLink">
                        <FaLinkedinIn />
                    </Link>
                </div> */}
            </div>
            <div className="Footer__right">
                <div className="Footer__box">
                    <p className="Footer__title">O produkcie</p>
                    <a href="#about" className="Footer__link">
                        Więcej o produkcie
                    </a>
                    <a href="#offer" className="Footer__link">
                        Co oferujemy
                    </a>
                    <a href="#info" className="Footer__link">
                        Dla kogo jest ten produkt
                    </a>
                    <a href="#subscribe" className="Footer__link">
                        Zapisz się
                    </a>
                    {/* <Link to="/" className="Footer__link">
                        Polityka prywatności
                    </Link> */}
                </div>
            </div>
            <div className="Footer__right">
                <div className="Footer__box">
                    <p className="Footer__title">Przejdź do sekcji</p>
                    <a href="#home" className="Footer__link">
                        Strona główna
                    </a>
                    <a href="#about" className="Footer__link">
                        O produkcie
                    </a>
                    <a href="#offer" className="Footer__link">
                        Oferta
                    </a>
                    <a href="#info" className="Footer__link">
                        Dla kogo
                    </a>
                    {/* <Link to="/" className="Footer__link">
                        Polityka prywatności
                    </Link> */}
                </div>
            </div>
            <div className="Footer__right">
                <div className="Footer__box">
                    <p className="Footer__title">Moje dane</p>
                    <Link to="/privacypolicy" className="Footer__link">
                        Polityka prywatności
                    </Link>
                    <Link to="/cookies" className="Footer__link">
                        Ciasteczka
                    </Link>
                    <Link to="/unsubscribe" className="Footer__link">
                        Wypisz się
                    </Link>
                    {/* <a href="#form" className="Footer__link">
                        Mój profil
                    </a> */}
                </div>
            </div>
        </footer>
    );
};
export default Footer;
