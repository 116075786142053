import "./LandingPage.scss";
import HeroBackground from "../../images/svg/HeroBackground";
import ReCAPTCHA from "react-google-recaptcha";

import {
    FaArrowRight,
    FaCheck,
    FaBuffer,
    FaWpforms,
    FaCalendarCheck,
} from "react-icons/fa";
import React, { useState, useRef } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firstImg from "../../images/main1.svg";
import secondImg from "../../images/main2.svg";
import img1 from "../../images/1.png";
import img2 from "../../images/2.0.png";
import img3 from "../../images/3.png";
import img4 from "../../images/4.1.png";
import img5 from "../../images/4.2.png";
import img6 from "../../images/5.png";

// import Companies from "../../components/Companies/Companies";

const LandingPage = () => {
    const recaptcha = useRef();
    const [mail, setMail] = useState();

    // ogólnie działa ale lepsze są metody domyślne jak writeuserdata,
    // tylko ze to domyslne nadpisuje dane zamiast dodawac nowe, stad tymczasowo dodałem to jako zwykły post, ale potem lepiej poszukac lepszej opcji
    async function addApplicationHandler(mail) {
        const url = `https://huwe-3b998-default-rtdb.firebaseio.com//users.json`;
        const isValidEmail =
            mail &&
            mail?.length &&
            mail?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);

        if (!isValidEmail) {
            toast.warning("Wprowadź poprawny adres email!");
            return null;
        }
        await fetch(url, {
            method: "POST",
            body: JSON.stringify({
                mail,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                let success = response.ok;

                response.json().then((response) => {
                    if (!success) {
                        //handle errors here
                        toast.error("Wystąpił błąd! Spróbuj ponownie później");
                        return;
                    }

                    // handle successful requests here
                    toast.success(
                        "Dziękujemy! Odezwiemy się w ciągu 24 godzin"
                    );
                });
            })
            .catch((error) => {
                // catch any unexpected errors
                toast.error("Wystąpił błąd! Spróbuj ponownie później");
                console.log(error);
            });
    }

    const formSubmitHandler = (event) => {
        event.preventDefault();
        event.preventDefault();
        const captchaValue = recaptcha.current.getValue();
        if (!captchaValue) {
            toast.error("Proszę zweryfikować reCaptchę");
        } else {
            addApplicationHandler(mail);
        }
    };

    return (
        <section className="LandingPage">
            <ToastContainer />
            <div id="home" className="LandingPage__container container">
                <div className="LandingPage__box">
                    <HeroBackground />
                    <h1 className="LandingPage__title">
                        Prowadź jeszcze lepsze rekrutacje
                    </h1>
                    <p className="LandingPage__subtitle">
                        Zapisz się i sprawdź, jak wspólnie możemy uczynić każdy
                        proces rekrutacji wyjątkowym
                    </p>
                    {/* <a className="LandingPage__button" href="#subscribe">
                        <span>Wypróbuj za darmo</span> <FaArrowRight />
                    </a> */}
                    <div className="LandingPage__form">
                        <a className="LandingPage__button" href="#subscribe">
                            <span>Zapisz się</span> <FaArrowRight />
                        </a>
                    </div>
                </div>
            </div>
            {/* <Companies /> */}

            {/*  */}
            <div className="Infosection--gray">
                <div
                    id="howitworks"
                    className="LandingPage__infosection Infosection container Infosection--gray"
                >
                    <div className="Infosection__box Infosection__box--content">
                        <h2>
                            <span>01</span> Dodawaj oferty
                        </h2>
                        <div className="Infosection__subbox">
                            <FaCheck />
                            <span>
                                sprawdź interaktywny proces rekrutacji bez
                                konieczności posiadania CV
                            </span>
                        </div>
                        <div className="Infosection__subbox">
                            <FaCheck />{" "}
                            <span>
                                publikuj oferty pracy jednocześnie na wielu
                                portalach dzięki wbudowanym integracjom
                            </span>
                        </div>
                        <div className="Infosection__subbox">
                            <FaCheck />{" "}
                            <span>twórz oferty w zaawansowanym panelu</span>
                        </div>
                        <div className="Infosection__subbox">
                            <FaCheck />{" "}
                            <span>
                                dodaj stronę z opisem oferty i przedstaw swoją
                                firmę
                            </span>
                        </div>

                        <div className="Infosection__subbox">
                            <FaCheck />
                            <span>zarządzaj rekrutacjami od A-Z</span>
                        </div>
                    </div>
                    <div className="Infosection__box Infosection__box--imagebox">
                        <img alt="" src={img1} loading="lazy" />
                    </div>
                </div>
            </div>
            <div className="LandingPage__infosection Infosection container">
                <div className="Infosection__box Infosection__box--imagebox">
                    <img alt="" src={img5} loading="lazy" />
                </div>
                <div className="Infosection__box Infosection__box--content">
                    <h2>
                        <span className="gray">02</span>Sprawdzaj <br />
                        kandydatów
                    </h2>
                    <div className="Infosection__subbox">
                        <span>
                            Sprawdzaj umiejętności i osobowość kandydatów w
                            interaktywny sposób. Wykorzystuj różnorodne moduły i
                            wybieraj osoby najbardziej spełniające oczekiwania i
                            pasujące do zespołu
                        </span>
                    </div>
                </div>
            </div>
            <div className="Infosection--gray">
                <div className="LandingPage__infosection Infosection container">
                    <div className="Infosection__box Infosection__box--content">
                        <h2>
                            <span>03</span> Automatyzuj feedback
                        </h2>
                        <div className="Infosection__subbox">
                            <FaCheck />
                            <span>
                                spraw żeby każdy kandydat czuł się zaopiekowany
                            </span>
                        </div>
                        <div className="Infosection__subbox">
                            <FaCheck />
                            <span>twórz reużywalne szablony wiadomości</span>
                        </div>
                        <div className="Infosection__subbox">
                            <FaCheck />{" "}
                            <span>
                                wysyłaj dziesiątki maili jednym kliknięciem
                            </span>
                        </div>
                        <div className="Infosection__subbox">
                            <FaCheck />{" "}
                            <span>
                                w razie potrzeby dodawaj spersonalizowane treści
                                dla każdego z kandydatów
                            </span>
                        </div>
                        <div className="Infosection__subbox">
                            <FaCheck />{" "}
                            <span>
                                zarządzaj całym procesem mailingowym w jednym
                                miejscu
                            </span>
                        </div>
                    </div>
                    <div className="Infosection__box Infosection__box--imagebox">
                        <img alt="" src={img3} loading="lazy" />
                    </div>
                </div>
            </div>
            <div className="LandingPage__infosection Infosection container">
                <div className="Infosection__box Infosection__box--imagebox">
                    <img alt="" src={img4} loading="lazy" />
                </div>
                <div className="Infosection__box Infosection__box--content">
                    <h2>
                        <span className="gray">04</span> Rekrutuj bez CV
                    </h2>
                    <div className="Infosection__subbox">
                        <span>
                            Daj kandydatom równe szanse na pokazanie swoich
                            umiejętności i osobowości w kreatywny sposób i
                            poznaj ich lepiej jeszcze przed pierwszą rozmową
                            Zachęć najlepszych do aplikowania dzięki
                            rozwiązaniu, które pozwala prowadzić interaktywne
                            procesy bez konieczności opierania się jedynie na CV
                        </span>
                    </div>
                </div>
            </div>
            <div className="LandingPage__infosection Infosection container">
                <div className="Infosection__box Infosection__box--content">
                    <h2>
                        <span>05</span> Zarządzaj rekrutacjami
                    </h2>
                    <div className="Infosection__subbox">
                        <FaCheck />
                        <span>
                            w prosty sposób prowadź wiele rekrutacji
                            jednocześnie
                        </span>
                    </div>
                    <div className="Infosection__subbox">
                        <FaCheck />
                        <span>
                            kontroluj statystyki i ulepszaj swoje rekrutacje
                        </span>
                    </div>
                    <div className="Infosection__subbox">
                        <FaCheck />{" "}
                        <span>
                            dziel obowiązki rekrutacyjne pomiędzy członków
                            zespołu
                        </span>
                    </div>
                    <div className="Infosection__subbox">
                        <FaCheck />
                        <span>
                            monitoruj koszty przeprowadzenia każdej rekrutacji
                        </span>
                    </div>
                </div>
                <div className="Infosection__box Infosection__box--imagebox">
                    <img alt="" src={img2} loading="lazy" />
                </div>
            </div>
            <div className="Infosection--gray">
                <div className=" LandingPage__infosection Infosection container">
                    <div className="Infosection__box Infosection__box--imagebox">
                        <img alt="" src={img6} loading="lazy" />
                    </div>
                    <div className="Infosection__box Infosection__box--content">
                        <h2>
                            <span>06</span>Miej wszystko pod ręką
                        </h2>
                        <div className="Infosection__subbox">
                            <span>
                                Analizuj, porównuj i oceniaj dopasowanie
                                kandydatów, badając ich wyniki testów,
                                oczekiwania oraz wiedzę techniczną. Dzięki
                                profilom kandydatów skrócisz czas wstępnej
                                weryfikacji do minimum, gwarantując, że żaden
                                talent Ci nie umknie.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <div
                id="about"
                className="LandingPage__infosection Infosection container"
            >
                <div className="Infosection__box Infosection__box--content">
                    <h2>O produkcie</h2>
                    <div className="Infosection__subbox">
                        <FaCheck />
                        <span>
                            interaktywny proces rekrutacji bez konieczności
                            posiadania CV
                        </span>
                    </div>
                    <div className="Infosection__subbox">
                        <FaCheck />
                        <span>system do zarządzania rekrutacjami od A-Z</span>
                    </div>
                    <div className="Infosection__subbox">
                        <FaCheck />{" "}
                        <span>zaawansowany panel do tworzenia ofert pracy</span>
                    </div>
                    <div className="Infosection__subbox">
                        <FaCheck />{" "}
                        <span>
                            prosta w edycji dedykowana strona z opisem oferty
                        </span>
                    </div>
                    <div className="Infosection__subbox">
                        <FaCheck />{" "}
                        <span>integracja z serwisami oferującymi pracę</span>
                    </div>
                    <a className="Infosection__button" href="#subscribe">
                        <span>Zapisz się</span> <FaArrowRight />
                    </a>
                </div>
                <div className="Infosection__box Infosection__box--imagebox">
                    <img alt="" src={firstImg} />
                </div>
            </div>
            <div id="offer" className="LandingPage__benefits Benefits">
                <div className="Benefits__box Benefits__box--header">
                    <h2>Oferta</h2>
                </div>
                <div className="Benefits__box Benefits__box--content">
                    <span>
                        <FaWpforms />
                    </span>
                    <h3>Rekrutacja bez CV</h3>
                    <p>
                        Zachęć kandydatów interaktywną rekrutacją i poznaj ich
                        lepiej jeszcze przed pierwszą rozmową
                    </p>
                </div>
                <div className="Benefits__box Benefits__box--content">
                    <span>
                        <FaBuffer />
                    </span>
                    <h3>Produkt kompletny</h3>
                    <p>
                        Jednym kliknięciem opublikuj ofertę na wybranych
                        portalach i zarządzaj aplikacjami kandydatów w prosty
                        sposób
                    </p>
                </div>
                <div className="Benefits__box Benefits__box--content">
                    <span>
                        <FaCalendarCheck />
                    </span>
                    <h3>Dbałość o szczegóły</h3>
                    <p>
                        Zobacz jak dzięki automatyzacji możesz niewielkim
                        kosztem sprawić, że każdy z kandydatów poczuje się
                        wyjątkowo
                    </p>
                </div>
            </div>
            <div
                id="info"
                className="LandingPage__infosection Infosection container"
            >
                <div className="Infosection__box Infosection__box--imagebox">
                    <img alt="" src={secondImg} />
                </div>
                <div className="Infosection__box Infosection__box--content">
                    <h2>Dla kogo</h2>
                    <div className="Infosection__subbox">
                        <span>
                            Dla tych, którzy wiedzą jak ważna jest dobrze
                            prowadzona rekrutacja, tych którzy doceniają dbałość
                            o szczegóły i tych którzy chcą zatrudniać
                            najlepszych pracowników pasujacych do zespołu.
                        </span>
                    </div>
                    <a className="Infosection__button" href="#subscribe">
                        <span>Dowiedz się więcej</span> <FaArrowRight />
                    </a>
                </div>
            </div>
            {/* <div className="LandingPage__prices Prices">
                <div className="Prices__box Prices__box--header">
                    <h2>Wybierz plan dla swoich potrzeb</h2>
                </div>
                <div className="Prices__box--content">
                    <div className="Prices__innerBox">
                        <div className="Prices__title">free</div>
                        <div>
                            <div className="Prices__price">0 PLN</div>
                            <div className="Prices__priceDetails">
                                opłaty nie zostaną naliczone
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <strong>Zyskujesz:</strong>
                                </li>
                                <li>- test</li>
                                <li>- dostęp test</li>
                                <li>- test 2</li>
                                <li>- wgląd w lorem ipsum</li>
                            </ul>
                        </div>
                        <a className="Prices__button" href="#form">
                            Wybierz
                        </a>
                    </div>
                </div>
                <div className="Prices__box--content">
                    <div className="Prices__innerBox">
                        <div className="Prices__title">profesjonalny</div>
                        <span>Popularny</span>
                        <div>
                            <div className="Prices__price">39 PLN</div>
                            <div className="Prices__priceDetails">
                                opłata zostanie pobrana automatycznie co miesiąc
                            </div>
                        </div>
                        <div className="Prices__benefits">
                            <ul>
                                <li>
                                    <strong>Zyskujesz:</strong>
                                </li>
                                <li>- test 1</li>
                                <li>- dostęp test 2</li>
                                <li>- test 2234</li>
                                <li>- wgląd w lorem ipsum</li>
                            </ul>
                        </div>
                        <a className="Prices__button" href="#form">
                            Wypróbuj za darmo
                        </a>
                    </div>
                </div>
                <div className="Prices__box--content">
                    <div className="Prices__innerBox">
                        <div className="Prices__title">premium</div>
                        <div>
                            <div className="Prices__price">69 PLN</div>
                            <div className="Prices__priceDetails">
                                opłata zostanie pobrana automatycznie co miesiąc
                            </div>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <strong>Zyskujesz:</strong>
                                </li>
                                <li>- test 00</li>
                                <li>- dostęp 1 test</li>
                                <li>- test 4</li>
                                <li>- wgląd w lorem ipsum</li>
                                <li>- wgląd w lorem ipsum lorem</li>
                            </ul>
                        </div>
                        <Link className="Prices__button" to="/register">
                            Wypróbuj za darmo
                        </Link>
                    </div>
                </div>
            </div> */}
            <div id="subscribe" className="LandingPage__try Try">
                <div className="Try__box">
                    <p className="Try__subtitle">
                        Dowiedz się więcej o tym jak chcemy Ci pomóc!
                    </p>
                    <h2 className="Try__heading">
                        Zapisz się i sprawdź jak wspólnie stworzymy wyjątkowe
                        rekrutacje
                    </h2>
                    <form className="Try__form" onSubmit={formSubmitHandler}>
                        <label htmlFor="email">Zostaw swój adres email</label>
                        <input
                            name="email"
                            type="text"
                            placeholder="Adres email..."
                            onChange={(e) => setMail(e.target.value)}
                        />
                        <button className="Try__button" type="submit">
                            <span>Zapisz się</span> <FaArrowRight />
                        </button>
                        <ReCAPTCHA
                            ref={recaptcha}
                            sitekey={process.env.REACT_APP_SITE_KEY}
                        />
                    </form>
                </div>
            </div>
        </section>
    );
};
export default LandingPage;
