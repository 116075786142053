import "./Header.scss";

import logo from "../../images/logo.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import Navigation from "../Navigation/Navigation";

const Header = () => {
    const [user, setUser] = useState("");
    const auth = getAuth();
    const isLoggedIn = () => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                setUser(uid);
            } else {
                setUser("");
            }
        });
    };
    const [menuState, setMenuState] = useState(false);
    const isMobileMenuOpen = menuState;
    const headerClasses = isMobileMenuOpen
        ? ["App__header container mobileMenuOpen"]
        : ["App__header container"];

    useEffect(() => {
        isLoggedIn();
    });
    useEffect(() => {
        isMobileMenuOpen && document.body.classList.add("no-scroll");
        !isMobileMenuOpen && document.body.classList.remove("no-scroll");
    }, [isMobileMenuOpen]);
    return (
        <header className={headerClasses}>
            <Link to="/" className="App__logo" title="Huwe logo">
                <img className="App__logo--image" alt="" src={logo} />
            </Link>
            <button
                className="NavigationMobile"
                onClick={() => setMenuState(!menuState)}
                aria-label="button"
            >
                <span></span>
                <span></span>
                <span></span>
            </button>
            <Navigation user={user} setMenuState={setMenuState} />
        </header>
    );
};

export default Header;
