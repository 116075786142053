import { Link } from "react-router-dom";
import {
    FaArrowRight,
    FaMoneyBill,
    FaRocket,
    FaGraduationCap,
} from "react-icons/fa";
import Checkbox from "@mui/material/Checkbox";
import { FaArrowLeft } from "react-icons/fa";
import logo from "../../images/logo.png";
import Checkmarks from "../../components/Checkmarks/Checkmarks";
import "./RecruitmentBoard.scss";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const RecruitmentBoard = () => {
    return (
        <section className="RecruitmentBoard">
            <Link className="RecruitmentBoard__back" to="/app">
                <FaArrowLeft /> <span> Wróć do strony głównej </span>
            </Link>
            <section className="RecruitmentBoard__section RecruitmentBoard__section--left">
                <Link to="/" className="App__logo">
                    <img className="App__logo--image" alt="" src={logo} />
                </Link>
                <div className="RecruitmentBoard__box">
                    <h1>Rekrutacja na stanowisko XZYX</h1>
                    <div class="card style3 filters">
                        <MultipleSelect />
                        <Checkmarks />
                        <Checkmarks />
                        <div>
                            <Checkbox
                                {..."Test"}
                                defaultChecked
                                color="success"
                            />
                            <span>Tylko spełniający wymagania</span>
                        </div>
                    </div>
                </div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0} className="gridContainer">
                        <Grid item xs={3} className="gridBox">
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <span className="boxTitle">Etap I</span>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 4, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item className="UserWrapper">
                                    <div className="ImgWrapper">
                                        <img
                                            src="https://placehold.co/75x75/d3d3d3/FFF"
                                            alt=""
                                        />
                                        <div className="cardHeader">
                                            <p>Imię i nazwisko</p>
                                            <div className="RecruitmentBoardUserData">
                                                <FaGraduationCap />
                                                <span>10 lat 6 mies.</span>
                                            </div>
                                            <span className="RecruitmentBoardUserCircle">
                                                <span className="PercentageLabel">
                                                    85%
                                                </span>
                                                <CircularProgress
                                                    variant="determinate"
                                                    value={85}
                                                    color="success"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="RecruitmentBoardUserDataWrapper">
                                        <div className="RecruitmentBoardUserData">
                                            <FaMoneyBill />
                                            <span>10 500 PLN</span>
                                        </div>
                                        <div className="RecruitmentBoardUserData">
                                            <FaRocket />
                                            <span>1.05.2024</span>
                                        </div>
                                        <div className="RecruitmentBoardUserData Red">
                                            Odrzuć
                                        </div>
                                        <div className="RecruitmentBoardUserData Green">
                                            Akceptuj
                                        </div>
                                    </div>
                                    <div className="RecruitmentBoardBox">
                                        <div class="card style3">
                                            <div>
                                                <img
                                                    src="https://placehold.co/200x200/d3d3d3/FFF"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="contentWrapper">
                                                <div className="tempName1">
                                                    <div className="cardHeader">
                                                        Imię i nazwisko
                                                    </div>
                                                    <span>Toruń, Polska</span>
                                                    <span>
                                                        test.torun@pp.pl
                                                    </span>
                                                    <span>662 222 222</span>
                                                </div>
                                                <div className="tempName2">
                                                    <span>
                                                        Oczekiwania finansowe:
                                                        10 500 PLN{" "}
                                                    </span>
                                                    <span>
                                                        Doświadczenie: 10 lat
                                                    </span>
                                                    <span>
                                                        Możliwy start: 1.05.2024
                                                    </span>
                                                    <span>Dopasowanie</span>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={100}
                                                        color="success"
                                                    />
                                                    <div className="">
                                                        <p>Mocne strony</p>
                                                        <div className="CreateOffer__tag">
                                                            React
                                                        </div>
                                                        <div className="CreateOffer__tag">
                                                            JavaScript
                                                        </div>
                                                        <div className="CreateOffer__tag">
                                                            Angular
                                                        </div>
                                                        <div className="CreateOffer__tag">
                                                            Java
                                                        </div>
                                                        <div className="CreateOffer__tag">
                                                            Angielski
                                                        </div>
                                                    </div>

                                                    <div className="RecruitmentBoard__publish">
                                                        Zobacz profil
                                                        <FaArrowRight />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Item>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className="gridBox">
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <span className="boxTitle">Etap II</span>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 4, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className="gridBox">
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <span className="boxTitle">Etap III</span>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 4, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className="gridBox">
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <span className="boxTitle">Etap IV</span>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 4, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                            <Grid item xs={12} sx={{ paddingTop: 1, px: 0.5 }}>
                                <Item>xs=4</Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </section>
        </section>
    );
};

export default RecruitmentBoard;
