import { Link } from "react-router-dom";

import { FaArrowLeft } from "react-icons/fa";
import logo from "../../images/logo.png";

import "./Cookies.scss";
const Cookies = () => {
    return (
        <section className="Cookies container">
            <Link className="Cookies__back" to="/">
                <FaArrowLeft /> <span> Wróć do strony głównej </span>
            </Link>
            <section className="Cookies__section Cookies__section--left">
                <Link to="/" className="App__logo">
                    <img className="App__logo--image" alt="" src={logo} />
                </Link>

                <div className="Cookies__box">
                    <p>
                        <b>POLITYKA DOTYCZĄCA WYKORZYSTYWANIA COOKIES</b>
                        Stosowane Cookiesy są bezpieczne dla Urządzenia
                        Użytkownika. Pliki te pozwalają zidentyfikować
                        oprogramowanie wykorzystywane przez Użytkownika i
                        dostosować Serwis indywidualnie każdemu Użytkownikowi.
                        Cookies zazwyczaj zawierają nazwę domeny z której
                        pochodzą, czas przechowywania ich na Urządzeniu oraz
                        przypisaną wartość.
                        <b>CELE W JAKICH WYKORZYSTYWANE SĄ COOKIESY</b>
                        <ul>
                            <li>
                                - Zbieranie ogólnych i anonimowych danych
                                statystycznych za pośrednictwem narzędzi
                                analitycznych
                            </li>
                            <li>
                                - Analizowanie profilu użytkownika celem
                                tworzenia statystyk, które pomogą zrozumieć w
                                jaki sposób użytkownicy korzystają ze strony
                            </li>
                        </ul>
                        <b>
                            MOŻLIWOŚCI OKREŚLENIA WARUNKÓW PRZECHOWYWANIA LUB
                            UZYSKANIA DOSTĘPU DO COOKIES
                        </b>
                        Użytkownik może samodzielnie i w każdym czasie zmienić
                        ustawienia dotyczące plików Cookies, określając warunki
                        ich przechowywania i uzyskiwania dostępu przez pliki
                        Cookies do Urządzenia Użytkownika. Zmiany ustawień
                        użytkownik może dokonać za pomocą ustawień przeglądarki
                        internetowej lub za pomocą konfiguracji usługi.
                        Szczegółowe informacje o możliwości i sposobach obsługi
                        plików cookies dostępne są w ustawieniach oprogramowania
                        (przeglądarki internetowej). Użytkownik może też w
                        każdej chwili usunąć pliki Cookies korzystając z
                        dostępnych funkcji w przeglądarce internetowej, której
                        używa.
                    </p>
                </div>
            </section>
        </section>
    );
};

export default Cookies;
