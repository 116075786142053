import "./NavigationItem.scss";
import { FaArrowRight } from "react-icons/fa";
// import { Link } from "react-router-dom";

const NavigationItem = (props) => {
    const navigationItemClass = !props.arrow
        ? ["Navigation__item"]
        : ["Navigation__item Navigation__item--register"];

    const navigationLinkClass = !props.arrow
        ? ["Navigation__link"]
        : ["Navigation__link Navigation__link--register"];

    return (
        <li className={navigationItemClass}>
            <a
                className={navigationLinkClass}
                href={props.link}
                onClick={() => props.setMenuState(false)}
            >
                <span>{props.name}</span>
                {props.arrow && <FaArrowRight />}
            </a>
        </li>
    );
};

export default NavigationItem;
