import "./Navigation.scss";
import NavigationItem from "../NavigationItem/NavigationItem";

const Navigation = (props) => {
    // const isLoggedIn = props.user;
    return (
        <nav className="App__nav Navigation">
            <ul className="Navigation__list">
                {/* <NavigationItem
                    name="App"
                    link="/app"
                    setMenuState={props.setMenuState}
                /> */}
                {/* <NavigationItem
                    name="1. create"
                    link="/createoffer"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="2. active rec"
                    link="/activerecruitments"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="2.1list"
                    link="/list"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="2.2board"
                    link="/board"
                    setMenuState={props.setMenuState}
                />

                <NavigationItem
                    name="3. mailing"
                    link="/mailing"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="4. process"
                    link="/process"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="5. candidate"
                    link="/candidate"
                    setMenuState={props.setMenuState}
                /> */}
                <NavigationItem
                    name="Strona główna"
                    link="/"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="Jak to działa"
                    link="#howitworks"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="O produkcie"
                    link="#about"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="Oferta"
                    link="#offer"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="Dla kogo"
                    link="#info"
                    setMenuState={props.setMenuState}
                />
                <NavigationItem
                    name="Zapisz się"
                    arrow={true}
                    link="#subscribe"
                    setMenuState={props.setMenuState}
                />
                {/* {isLoggedIn && (
                    <NavigationItem
                        name="Zmodyfikuj profil"
                        link="/update-profile"
                    />
                )}
                {isLoggedIn && (
                    <NavigationItem name="Twoje konto" link="/dashboard" />
                )}
                {!isLoggedIn && (
                    <NavigationItem name="Logowanie" link="/signin" />
                )}
                {!isLoggedIn && (
                    <NavigationItem
                        name="Wypróbuj za darmo"
                        arrow={true}
                        link="/register"
                    />
                )} */}
            </ul>
        </nav>
    );
};

export default Navigation;
