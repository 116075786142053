import { Link } from "react-router-dom";
import { FaArrowRight, FaEnvelope, FaHome, FaPhone } from "react-icons/fa";
import Checkbox from "@mui/material/Checkbox";
import { FaArrowLeft } from "react-icons/fa";
import logo from "../../images/logo.png";
import Checkmarks from "../../components/Checkmarks/Checkmarks";
import "./RecruitmentList.scss";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import LinearProgress from "@mui/material/LinearProgress";

const RecruitmentList = () => {
    return (
        <section className="RecruitmentList">
            <Link className="RecruitmentList__back" to="/app">
                <FaArrowLeft /> <span> Wróć do strony głównej </span>
            </Link>
            <section className="RecruitmentList__section RecruitmentList__section--left">
                <Link to="/" className="App__logo">
                    <img className="App__logo--image" alt="" src={logo} />
                </Link>
                <div className="RecruitmentList__box">
                    <div class="card style3 filters">
                        <MultipleSelect />
                        <Checkmarks />
                        <div>
                            <Checkbox
                                {..."Test"}
                                defaultChecked
                                color="success"
                            />
                            <span>Tylko spełniający wymagania</span>
                        </div>
                    </div>
                </div>
                <div class="card style3 candidates">
                    <div>
                        Pasuje do wymagań:
                        <b> 11/245 kandydatów</b>
                    </div>
                </div>
                <div className="RecruitmentList__box">
                    <div class="card style3">
                        <div>
                            <img
                                src="https://placehold.co/200x200/d3d3d3/FFF"
                                alt=""
                            />
                        </div>
                        <div className="contentWrapper">
                            <div className="tempName1">
                                <div className="cardHeader">
                                    Imię i nazwisko
                                </div>
                                <div>
                                    <FaHome />
                                    <span> Toruń, Polska </span>
                                </div>
                                <div>
                                    <FaEnvelope />
                                    <span> test.torun@tt.pl </span>
                                </div>
                                <div>
                                    <FaPhone /> <span> 662 222 222 </span>
                                </div>
                            </div>
                            <div className="tempName2">
                                <div className="RecruitmentList__progressBar">
                                    <span>Dopasowanie</span>
                                    <LinearProgress
                                        variant="determinate"
                                        value={100}
                                        color="success"
                                    />
                                </div>
                                <span>Oczekiwania finansowe: 10 500 PLN </span>
                                <span>Doświadczenie: 10 lat</span>
                                <span>Możliwy start: 1.05.2024</span>

                                <div className="RecruitmentList__publish">
                                    Zobacz profil
                                    <FaArrowRight />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="RecruitmentList__box">
                    <div class="card style3">
                        <div>
                            <img
                                src="https://placehold.co/200x200/d3d3d3/FFF"
                                alt=""
                            />
                        </div>
                        <div className="contentWrapper">
                            <div className="tempName1">
                                <div className="cardHeader">
                                    Imię i nazwisko
                                </div>
                                <div>
                                    <FaHome />
                                    <span> Toruń, Polska </span>
                                </div>
                                <div>
                                    <FaEnvelope />
                                    <span> test.torun@tt.pl </span>
                                </div>
                                <div>
                                    <FaPhone /> <span> 662 222 222 </span>
                                </div>
                            </div>
                            <div className="tempName2">
                                <div className="RecruitmentList__progressBar">
                                    <span>Dopasowanie</span>
                                    <LinearProgress
                                        variant="determinate"
                                        value={100}
                                        color="success"
                                    />
                                </div>
                                <span>Oczekiwania finansowe: 10 500 PLN </span>
                                <span>Doświadczenie: 10 lat</span>
                                <span>Możliwy start: 1.05.2024</span>

                                <div className="RecruitmentList__publish">
                                    Zobacz profil
                                    <FaArrowRight />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="RecruitmentList__box">
                    <div class="card style3">
                        <div>
                            <img
                                src="https://placehold.co/200x200/d3d3d3/FFF"
                                alt=""
                            />
                        </div>
                        <div className="contentWrapper">
                            <div className="tempName1">
                                <div className="cardHeader">
                                    Imię i nazwisko
                                </div>
                                <div>
                                    <FaHome />
                                    <span> Toruń, Polska </span>
                                </div>
                                <div>
                                    <FaEnvelope />
                                    <span> test.torun@tt.pl </span>
                                </div>
                                <div>
                                    <FaPhone /> <span> 662 222 222 </span>
                                </div>
                            </div>
                            <div className="tempName2">
                                <div className="RecruitmentList__progressBar">
                                    <span>Dopasowanie</span>
                                    <LinearProgress
                                        variant="determinate"
                                        value={100}
                                        color="success"
                                    />
                                </div>
                                <span>Oczekiwania finansowe: 10 500 PLN </span>
                                <span>Doświadczenie: 10 lat</span>
                                <span>Możliwy start: 1.05.2024</span>

                                <div className="RecruitmentList__publish">
                                    Zobacz profil
                                    <FaArrowRight />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default RecruitmentList;
