import { Link } from "react-router-dom";

import { FaArrowLeft } from "react-icons/fa";
import logo from "../../images/logo.png";

import "./PrivacyPolicy.scss";
const PrivacyPolicy = () => {
    return (
        <section className="PrivacyPolicy container">
            <Link className="PrivacyPolicy__back" to="/">
                <FaArrowLeft /> <span> Wróć do strony głównej </span>
            </Link>
            <section className="PrivacyPolicy__section PrivacyPolicy__section--left">
                <Link to="/" className="App__logo">
                    <img className="App__logo--image" alt="" src={logo} />
                </Link>

                <div className="PrivacyPolicy__box">
                    <p>
                        <b>INFORMACJE OGÓLNE</b>
                        Administratorem danych osobowych przekazywanych
                        dobrowolnie przez Klientów jest firma HUWE. Z
                        administratorem można skontaktować się wysyłając maila
                        na adres e-mail: contact@huwe.pl
                        <b>CELE I PODSTAWY PRZETWARZANIA DANYCH OSOBOWYCH</b>
                        Dane osobowe będą przetwarzane:
                        <ul>
                            <li>
                                - w celu przesyłania wiadomości zawierających
                                informacje handlowe (marketing bezpośredni) (na
                                podstawie art. 6 pkt. 1 lit. f RODO);
                            </li>
                            - w celu statystycznym (na podstawie art. 6 pkt. 1
                            lit. f RODO);
                            <li></li>
                            <li>
                                - w celu badania satysfakcji Klientów (na
                                podstawie art. 6 pkt. 1 lit. f RODO).
                            </li>
                        </ul>
                        <b>ZAKRES PRZETWARZANYCH DANYCH OSOBOWYCH</b>
                        Zakres przetwarzanych danych osobowych obejmuje całość
                        danych przekazanych firmie HUWE, tj.:
                        <ul>
                            <li>- Adres poczty elektronicznej</li>
                        </ul>
                        <b>
                            PLIKI COOKIES I ZAUTOMATYZOWANE PRZECHOWYWANIE
                            DANYCH
                        </b>
                        Administrator na swojej stronie internetowej huwe.pl
                        korzysta z plików cookies. Brak zmiany po stronie
                        Klienta ustawień przeglądarki jest równoznaczny z
                        akceptacją ich używania.Więcej informacji dotyczących
                        wykorzystywania plików cookies na stronie internetowej
                        huwe.pl można uzyskać pod poniższym linkiem:
                        https://www.huwe.pl/cookies Dane osobowe Klientów będą
                        przetwarzane w sposób zautomatyzowany, który polega na
                        wykorzystaniu danych osobowych do oceny niektórych
                        czynników osobowych. Profilowanie nie wywołuje żadnych
                        skutków prawnych wobec Klienta, służy w szczególności do
                        analizy preferencji i dostosowania wyświetlanych ofert.
                        <b>OKRES PRZECHOWYWANIA DANYCH OSOBOWYCH</b>
                        Dane będą przetwarzanie do czasu zgłoszenia sprzeciwu
                        przez osobę/firmę, której dane dotyczą.
                        <b>
                            UPRAWNIENIA KLIENTA W ZAKRESIE PRZETWARZANIA DANYCH
                        </b>
                        Klient ma prawo do żądania dostępu do swoich danych
                        osobowych, ich sprostowania, usunięcia lub ograniczenia
                        przetwarzania, a także prawo do wniesienia sprzeciwu
                        wobec przetwarzania oraz prawo do przenoszenia
                        danych.Klient ma prawo do wniesienia skargi do organu
                        nadzorczego, którym jest Prezes Urzędu Ochrony Danych
                        Osobowych. W sprawach nieuregulowanych regulaminem
                        zastosowanie mają postanowienia Kodeksu cywilnego i
                        odpowiednich ustaw prawa polskiego, a także prawa Unii
                        Europejskiej, w szczególności RODO (Rozporządzenie
                        Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27
                        kwietnia 2016 r. w sprawie ochrony osób fizycznych w
                        związku z przetwarzaniem danych osobowych i w sprawie
                        swobodnego przepływu takich danych oraz uchylenia
                        dyrektywy 95/46/WE).
                    </p>
                </div>
            </section>
        </section>
    );
};

export default PrivacyPolicy;
