import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import SignIn from "./pages/SignIn/SignIn";
// import Register from "./pages/Register/Register";
import { AuthProvider } from "./contexts/AuthContext";
// import Dashboard from "./pages/Dashboard/Dashboard";
// import PrivateRoute from "./components/PrivateRoute";
// import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
// import UpdateProfile from "./pages/UpdateProfile/UpdateProfile";
// import ThanksForRegister from "./pages/ThanksForRegister/ThanksForRegister";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";

import LandingPage from "./pages/LandingPage/LandingPage";
// import Profile from "./pages/Profile/Profile";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Cookies from "./pages/Cookies/Cookies";
import CreateOffer from "./pages/CreateOffer/CreateOffer";
import ActiveRecruitments from "./pages/ActiveRecruitments/ActiveRecruitments";
import RecruitmentList from "./pages/RecruitmentList/RecruitmentList";
import RecruitmentBoard from "./pages/RecruitmentBoard/RecruitmentBoard";
import CandidateProfile from "./pages/CandidateProfile/CandidateProfile";
import RecruitmentProcess from "./pages/RecruitmentProcess/RecruitmentProcess";
import Mailing from "./pages/Mailing/Mailing";
import AppPage from "./pages/AppPage/AppPage";

function App() {
    return (
        <AuthProvider>
            <div className="App">
                <Router>
                    <main>
                        <Switch>
                            {/* <Route path="/signin">
                                <h1>SignIn Page</h1>
                                <SignIn />
                            </Route>
                            <Route path="/register">
                                <h1>Register Page</h1>
                                <Register />
                            </Route>
                            <Route path="/forgot-password">
                                <h1>Forgot password page</h1>
                                <ForgotPassword />
                            </Route>
                            <Route path="/thanks-for-register">
                                <h1>Thanks for Register Page</h1>
                                <ThanksForRegister />
                            </Route>
                            <PrivateRoute
                                path="/update-profile"
                                component={UpdateProfile}
                            ></PrivateRoute>
                            <PrivateRoute path="/dashboard">
                                <Header />
                                <Dashboard />
                                <Footer />
                            </PrivateRoute>
                            <Route path="/profile">
                                <Header />
                                <Profile />
                                <Footer />
                            </Route> */}
                            {/* <Route path="/app">
                                <AppPage />
                            </Route> */}
                            {/* <Route path="/candidate">
                                <CandidateProfile />
                            </Route>
                            <Route path="/process">
                                <RecruitmentProcess />
                            </Route>
                            <Route path="/mailing">
                                <Mailing />
                            </Route>
                            <Route path="/list">
                                <RecruitmentList />
                            </Route>
                            <Route path="/board">
                                <RecruitmentBoard />
                            </Route>
                            <Route path="/activerecruitments">
                                <ActiveRecruitments />
                            </Route>
                            <Route path="/createoffer">
                                <CreateOffer />
                            </Route> */}
                            <Route path="/unsubscribe">
                                <Unsubscribe />
                            </Route>
                            <Route path="/privacypolicy">
                                <PrivacyPolicy />
                            </Route>
                            <Route path="/cookies">
                                <Cookies />
                            </Route>
                            <Route path="/">
                                <Header />
                                <LandingPage />
                                <Footer />
                            </Route>
                        </Switch>
                    </main>
                </Router>
            </div>
        </AuthProvider>
    );
}

export default App;
