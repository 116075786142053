import { Link } from "react-router-dom";

import { FaArrowLeft } from "react-icons/fa";
import logo from "../../images/logo.png";

import "./Unsubscribe.scss";
const Unsubscribe = () => {
    return (
        <section className="Unsubscribe">
            <Link className="Unsubscribe__back" to="/">
                <FaArrowLeft /> <span> Wróć do strony głównej </span>
            </Link>
            <section className="Unsubscribe__section Unsubscribe__section--left">
                <Link to="/" className="App__logo">
                    <img className="App__logo--image" alt="" src={logo} />
                </Link>

                <div className="Unsubscribe__box">
                    <h2>Dziękujemy że jesteś z nami!</h2>
                    <p>
                        Jeśli chcesz się wypisać z bazy adresów email, prosimy o
                        przesłanie takiej informacji na adres email
                        contact@huwe.pl
                    </p>
                </div>
            </section>
        </section>
    );
};

export default Unsubscribe;
